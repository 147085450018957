<template>
    <v-card flat >
        <v-card-text>
            <div :class="[$slots.titleactions ? 'fbt-slider-title' : '', 'form-box-title']">
                <span>{{$t(title)}}</span><slot name="titleappend"></slot><slot name="titleactions"></slot></div>            
                <slot name="default"></slot>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "CardPanel",
    props: {
        title: {
            type: String,
            default: null
        }
    }
}
</script>